__webpack_public_path__ = "http://dev.local:8080/assets/";

import "../scss/application.scss";
import {onLoading} from "./_modules/website";
import {loadPage} from "./_modules/home";

var $ = require('jquery');



document.addEventListener("DOMContentLoaded", () => {
    onLoading();
    loadPage();


 /*   var coll = document.getElementsByClassName("collapsible");
    var i;

    var coll = document.getElementsByClassName("collapsible");
    var i;

    for (i = 0; i < coll.length; i++) {

        coll[i].addEventListener("click", function () {
            var button = this;
            this.classList.toggle("active");
            console.log(this);
            var content = this.nextElementSibling;
            var docHeight = window.innerHeight - 60;
            if (content.style.maxHeight) {
                content.style.maxHeight = null;
            } else {
                if (this.classList.contains("full-screen-collapsible")) {
                    content.style.maxHeight = docHeight + "px";
                    content.style.height = docHeight + "px";
                } else {
                    content.style.maxHeight = content.scrollHeight + "px";
                }
            }

            content.addEventListener("transitionend", function () {
                if (button.classList.contains('active')) {
                    button.classList.toggle("is-collapsing");
                } else {
                    button.classList.remove("is-collapsing");
                }
            });
        });
    }*/
});